import { useEffect, useState } from "react";
import {
  Button,
  Divider,
  Grid,
  Heading,
  IconButton,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Stack,
  Tab,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
  Tag,
  Text,
  useBoolean,
  HStack,
  Spacer,
  useToast,
  ButtonGroup,
} from "@chakra-ui/react";
import { MoreVert } from "@material-ui/icons";
import { useHistory } from "react-router-dom";

import { formatDateTime, humanize } from "@svix/common/utils";
import Card from "@svix/common/widgets/Card";
import ConfirmationDialog from "@svix/common/widgets/ConfirmationDialog";
import CodeEditor from "@svix/common/widgets/form/CodeEditor";
import { Lang } from "@svix/common/widgets/form/CodeEditor/Lang";
import LoadingIndicator from "@svix/common/widgets/LoadingIndicator";
import { MetaTitle } from "@svix/common/widgets/MetaTitle";
import {
  PageToolbar,
  BreadcrumbItem,
  Breadcrumbs,
  BreadcrumbItemWithId,
} from "@svix/common/widgets/PageToolbar";
import Stat from "@svix/common/widgets/Stat";

import { StreamSinkOut, StreamSinkStatus } from "src/api/streamSinks";
import { routeResolver } from "src/App";
import { useAppQuery } from "src/hooks/api";
import { useAppSelector } from "src/hooks/store";
import { useLoadingManual } from "src/utils";
import { SinkNames, StreamSinkStatusNames } from "../Sinks";

const exampleSink: StreamSinkOut = {
  id: "sink_2yVxRGfers6LVJtixrnmD",
  type: "amazonS3",
  config: {
    bucket: "asdfsdfsa",
    accessKeyId: "asdfasdfasd",
    region: "asdfasdf",
  },
  status: StreamSinkStatus.ENABLED,
  currentIterator: undefined,
  disabledReason: undefined,
  createdAt: new Date("2024-12-13T15:38:18.225Z"),
  updatedAt: new Date("2025-01-13T17:55:39.318297Z"),
  batchSize: 100,
  maxWaitSecs: 10,
  eventTypes: [],
};

export default function StreamSink() {
  const history = useHistory();
  const toast = useToast();
  const user = useAppSelector((state) => state.auth.user)!;

  // FIXME STREAM_APP_PORTAL: Replace with real API call
  const sinkId = "sink_2yVxRGfers6LVJtixrnmD";
  const queryKey = ["sinks", sinkId];
  const { data: sink } = useAppQuery(queryKey, async () => {
    return exampleSink;
  });

  const [deleteDialogOpen, setDeleteDialogOpen] = useBoolean(false);
  const deleteSink = async () => {
    // FIXME STREAM_APP_PORTAL: Implement
    toast({
      title: "Sink deleted",
      description: "The sink has been deleted successfully",
      status: "success",
    });
    history.push(routeResolver.getRoute("stream.sinks"));
  };

  if (!sink) {
    // FIXME STREAM_APP_PORTAL: Add skeleton for loading indicator
    return <LoadingIndicator />;
  }

  return (
    <>
      <MetaTitle path={[sink.uid ?? humanize(sinkId), "Sinks", user.app.name]} />
      <PageToolbar>
        <Breadcrumbs>
          <BreadcrumbItem to={routeResolver.getRoute("stream.sinks")}>
            Sinks
          </BreadcrumbItem>
          <BreadcrumbItemWithId identifier={sinkId} uid={sink.uid ?? undefined} />
        </Breadcrumbs>

        <Spacer />

        <Menu placement="bottom-end">
          <MenuButton as={IconButton} variant="rounded" data-cy="options-button">
            <MoreVert />
          </MenuButton>
          <MenuList data-cy="options-menu">
            <MenuItem textColor="text.danger" onClick={setDeleteDialogOpen.on}>
              Delete
            </MenuItem>
          </MenuList>
        </Menu>
      </PageToolbar>

      <Grid
        gridTemplateColumns={{
          sm: "minmax(0, 1fr)",
          md: "minmax(0, 3fr) minmax(240px, 1fr)",
        }}
        gap={8}
      >
        <Stack spacing={4}>
          <Card w="fit-content">
            <HStack>
              <Tag>{SinkNames[sink.type as keyof typeof SinkNames]}</Tag>
              <Divider w="5em" mx={4} />
              <Stat name="Status">
                <Tag
                  colorScheme={
                    StreamSinkStatusNames[sink.status as StreamSinkStatus].color
                  }
                >
                  {StreamSinkStatusNames[sink.status as StreamSinkStatus].name}
                </Tag>
              </Stat>
            </HStack>
          </Card>
          <Tabs>
            <TabList>
              <Tab>Overview</Tab>
              <Tab>Transformation</Tab>
            </TabList>

            <TabPanels>
              <TabPanel>
                <Card title="Configuration">
                  <Stack spacing={4}>
                    {/* FIXME STREAM_APP_PORTAL: Add all sink types */}
                    {sink.type === "amazonS3" && (
                      <>
                        <Stat name="Bucket">
                          <code>{sink.config.bucket}</code>
                        </Stat>
                        <Stat name="Region">
                          <code>{sink.config.region}</code>
                        </Stat>
                        <Stat name="Access Key ID">
                          <code>{sink.config.accessKeyId}</code>
                        </Stat>
                      </>
                    )}

                    <Stat name="Batch Size">{sink.batchSize}</Stat>

                    <Stat name="Max Wait (seconds)">{sink.maxWaitSecs}</Stat>
                  </Stack>
                </Card>
              </TabPanel>
              <TabPanel>
                <Card>
                  <Heading size="sm">Transformation function</Heading>
                  <Text variant="caption" fontSize="sm" mb={4}>
                    Transform the event before it is sent to the destination.
                  </Text>

                  <TransformationEditor sink={sink} />
                </Card>
              </TabPanel>
            </TabPanels>
          </Tabs>
        </Stack>

        <Stack spacing={4}>
          <Stat name="Creation Date">{formatDateTime(sink.createdAt)}</Stat>
          <Divider />
          <Stat name="Last Updated">{formatDateTime(sink.updatedAt)}</Stat>
        </Stack>
      </Grid>

      <ConfirmationDialog
        title="Deletion confirmation"
        isOpen={deleteDialogOpen}
        onCancel={() => setDeleteDialogOpen.off()}
        onOk={deleteSink}
        labelOk="Delete"
        colorScheme="red"
      >
        Are you sure you would like to permanently delete this sink?
      </ConfirmationDialog>
    </>
  );
}

function TransformationEditor({ sink }: { sink: StreamSinkOut }) {
  const toast = useToast();
  const { data } = useAppQuery(
    ["stream", "sinks", sink.id, "transformation"],
    async () => {
      // FIXME STREAM_APP_PORTAL: Replace with real API call
      return {
        code: "console.log(event)",
        enabled: true,
      };
    }
  );

  const [transformation, setTransformation] = useState<string | null>(null);

  const [isSubmitting, , updateTransformation] = useLoadingManual(async () => {
    // FIXME STREAM_APP_PORTAL: Implement
    toast({
      title: "Transformation saved",
      description: "The transformation has been updated successfully",
      status: "success",
    });
  }, [sink.id, transformation]);

  useEffect(() => {
    setTransformation(data?.code || "");
  }, [data]);

  return (
    <Stack>
      <CodeEditor
        lang={Lang.Javascript}
        value={transformation}
        dark
        onChange={setTransformation}
      />
      <HStack>
        <Spacer />
        {data?.code !== transformation && (
          <ButtonGroup>
            <Button
              colorScheme="gray"
              onClick={() => setTransformation(data?.code || "")}
            >
              Cancel
            </Button>
            <Button
              colorScheme="brand"
              isLoading={isSubmitting}
              onClick={updateTransformation}
            >
              Save
            </Button>
          </ButtonGroup>
        )}
      </HStack>
    </Stack>
  );
}
