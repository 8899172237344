import {
  Grid,
  Flex,
  Heading,
  Stack,
  Divider,
  Text,
  Tag,
  MenuItem,
  Menu,
  MenuButton,
  MenuList,
  IconButton,
  Box,
  Button,
  useBoolean,
} from "@chakra-ui/react";
import { MoreVert } from "@material-ui/icons";
import { useQueryClient } from "react-query";
import { useHistory, useParams } from "react-router-dom";
import { EndpointOut } from "svix";
import { AuthenticationApi } from "svix/dist/openapi";

import { formatDateTime } from "@svix/common/utils";
import Card from "@svix/common/widgets/Card";
import Code from "@svix/common/widgets/Code";
import ConfirmationDialog from "@svix/common/widgets/ConfirmationDialog";
import LoadingIndicator from "@svix/common/widgets/LoadingIndicator";
import { MetaTitle } from "@svix/common/widgets/MetaTitle";
import Mono from "@svix/common/widgets/Mono";
import {
  PageToolbar,
  BreadcrumbItem,
  Breadcrumbs,
  BreadcrumbItemWithId,
} from "@svix/common/widgets/PageToolbar";
import ResourceNotFound from "@svix/common/widgets/ResourceNotFound";
import Stat from "@svix/common/widgets/Stat";

import { getServerUrl, getSvix, SinkOut } from "src/api";
import { SinksApi } from "src/api/sinks";
import { routeResolver } from "src/App";
import EndpointDetailsTour from "src/components/Tours/EndpointDetailsTour";
import { useAppQuery } from "src/hooks/api";
import { useOrgSettings } from "src/hooks/common";
import { useAppSelector } from "src/hooks/store";
import { useLoadingManual } from "src/utils";
import ReadOnlyTooltip from "src/widgets/ReadOnlyTooltip";
import Channels from "../properties/Channels";
import EndpointToken, { NewEndpointToken } from "../properties/EndpointToken";
import EventTypes from "../properties/EventTypes";

export default function PollingEndpoint() {
  const queryClient = useQueryClient();
  const { sinkId } = useParams<{ sinkId: string }>();
  const user = useAppSelector((state) => state.auth.user)!;
  const queryKey = ["endpoints", "sinks", "polling", sinkId];
  const { isReadOnly, hideEventTypes } = useAppSelector((state) => state.embedConfig);
  const history = useHistory();

  const [isNewTokenOpen, setIsNewTokenOpen] = useBoolean(false);

  const { data: orgSettings } = useOrgSettings();

  const { data: sink, error } = useAppQuery(queryKey, async () => {
    const svix = getSvix();
    const api = new SinksApi(svix);
    return api.getSink(user.app.id, sinkId);
  });

  const apiKeyQueryKey = [...queryKey, "apikey"];
  const { data: apiKey, isLoading: isApiKeyLoading } = useAppQuery(
    apiKeyQueryKey,
    async () => {
      const svix = getSvix();
      const api = new AuthenticationApi(svix._configuration);
      const key = await api.v1AuthenticationGetPollerToken({
        appId: user.app.id,
        endpointId: sinkId,
      });
      return key;
    }
  );
  const [deleteDialogOpen, setDeleteDialogOpen] = useBoolean();
  const hasApiKey = !!apiKey && !isApiKeyLoading;

  const [, , setDisabled] = useLoadingManual(
    async (disabled: boolean) => {
      const api = getSvix();
      const patchedEndpoint = { ...asEndpoint(sink!), disabled };
      if (sink!.filterTypes && sink!.filterTypes.length === 0) {
        delete patchedEndpoint["filterTypes"];
      }

      await api.endpoint.update(user.app.id, sinkId, patchedEndpoint);
      queryClient.invalidateQueries(queryKey);
    },
    [sinkId, sink]
  );

  const [, , deleteEndpoint] = useLoadingManual(async () => {
    const api = getSvix();
    await api.endpoint.delete(user.app.id, sinkId);
    queryClient.invalidateQueries(queryKey);
    history.replace(routeResolver.getRoute("endpoints"));
  }, [sinkId, sink]);

  if (!sink) {
    return <LoadingIndicator />;
  }

  if (error) {
    return (
      <ResourceNotFound
        resourceName="endpoint"
        to={routeResolver.getRoute("endpoints")}
      />
    );
  }

  const sinkIdOrUid = sink.uid ?? sinkId;

  return (
    <>
      <MetaTitle path={[sinkIdOrUid, "Endpoints", user.app.name]} />
      <EndpointDetailsTour />
      <PageToolbar>
        <Breadcrumbs>
          <BreadcrumbItem to={routeResolver.getRoute("endpoints")}>
            Endpoints
          </BreadcrumbItem>
          <BreadcrumbItemWithId identifier={sinkId} uid={sink.uid ?? undefined} />
          {sink.disabled && (
            <Tag size="md" colorScheme="red" ml={2}>
              Disabled
            </Tag>
          )}
        </Breadcrumbs>
        <Flex flexGrow={1} />
        <Box>
          <Menu placement="bottom-end">
            <MenuButton as={IconButton} variant="rounded" data-cy="options-button">
              <MoreVert />
            </MenuButton>
            <MenuList data-cy="options-menu">
              <ReadOnlyTooltip readOnly={isReadOnly}>
                <MenuItem
                  isDisabled={isReadOnly}
                  onClick={() => setDisabled(!sink.disabled)}
                >
                  {sink.disabled ? "Enable" : "Disable"}
                </MenuItem>
              </ReadOnlyTooltip>
              <ReadOnlyTooltip readOnly={isReadOnly}>
                <MenuItem
                  isDisabled={isReadOnly}
                  onClick={setDeleteDialogOpen.on}
                  textColor="text.danger"
                >
                  Delete Endpoint
                </MenuItem>
              </ReadOnlyTooltip>
            </MenuList>
          </Menu>
        </Box>
      </PageToolbar>
      <Grid
        gridTemplateColumns={{
          sm: "minmax(0, 1fr)",
          md: "minmax(0, 3fr) minmax(240px, 1fr)",
        }}
        gap={8}
      >
        <Stack spacing={4}>
          <Heading size="md">Polling Endpoint</Heading>
          <Mono>
            {`${getServerUrl()}/api/v1/app/${user.app.id}/poller/${sinkIdOrUid}`}
          </Mono>
          <Card>
            <Stack spacing={8}>
              <Stack spacing={2}>
                <Text>
                  You can poll this endpoint to get the full list of messages sent to it.
                </Text>
                <Box>
                  <Code
                    language="bash"
                    code={getSampleCurl(user.app.id, sinkIdOrUid)}
                    copyToClipboard
                  />
                </Box>
                {!hasApiKey && (
                  <Button
                    colorScheme="blue"
                    variant="link"
                    w="fit-content"
                    onClick={setIsNewTokenOpen.on}
                  >
                    Create new API key
                  </Button>
                )}
              </Stack>
              <Stack spacing={2}>
                <Text>
                  When you first call the Polling Endpoint, it will return an empty array
                  and an <code>iterator</code>.
                </Text>
                <Box>
                  <Code language="bash" code={pollingIteratorSample} copyToClipboard />
                </Box>
              </Stack>
              <Stack spacing={2}>
                <Text>
                  Use the <code>iterator</code> in the next call to the Polling Endpoint
                  to return all messages received since the iterator was created.
                  <Text variant="caption">
                    Messages will be returned in the order they were received.
                  </Text>
                </Text>
                <Box>
                  <Code
                    language="bash"
                    code={getSampleCurl(
                      user.app.id,
                      sinkIdOrUid,
                      `${iteratorSample.slice(0, 4)}...`
                    )}
                    copyToClipboard
                  />
                </Box>
              </Stack>
            </Stack>
          </Card>
        </Stack>
        <Stack spacing={4}>
          <Stat name="Creation Date">{formatDateTime(sink.createdAt)}</Stat>
          <Divider />
          <Stat name="Last Updated">{formatDateTime(sink.updatedAt)}</Stat>
          <Divider />
          <EndpointToken endpointId={sinkId} />
          {!hideEventTypes && (
            <>
              <Divider />
              <EventTypes endpoint={asEndpoint(sink)} queryKey={queryKey} />
            </>
          )}
          {orgSettings?.enableChannels && (
            <>
              <Divider />
              <Channels endpoint={asEndpoint(sink)} queryKey={queryKey} />
            </>
          )}
        </Stack>
      </Grid>
      <ConfirmationDialog
        title="Deletion confirmation"
        isOpen={deleteDialogOpen}
        onCancel={setDeleteDialogOpen.off}
        onOk={deleteEndpoint}
        labelOk="Delete"
        colorScheme="red"
      >
        Are you sure you would like to permanently delete this endpoint?
      </ConfirmationDialog>
      <NewEndpointToken
        isOpen={isNewTokenOpen}
        onClose={setIsNewTokenOpen.off}
        queryKey={apiKeyQueryKey}
        endpointId={sinkId}
      />
    </>
  );
}

// Hack to use the endpoint APIs with a sink
const asEndpoint = (sink: SinkOut): EndpointOut => {
  return { url: "https://endpoint.sink", ...sink };
};

const iteratorSample = "eyJvZmZzZXQiOi05MjIzMzcyMDM2ODU0Nzc1ODA4LCJhZnRlciI6bnVsbH0";

const pollingIteratorSample = `{
  "data": [],
  "iterator": "${iteratorSample}",
  "done": false
}`;

// FIXME /events: Add token
const getSampleCurl = (appId: string, sinkId: string, iterator?: string) => {
  return `curl \\
  -X GET "${getServerUrl()}/api/v1/app/${appId}/poller/${sinkId}${
    iterator ? `?iterator=${iterator}` : ""
  }" \\
  -H 'Content-Type: application/json' \\
  -H 'Accept: application/json' \\
  -H 'Authorization: Bearer sk_poll_*****.eu'`;
};
